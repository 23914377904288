@font-face {
    font-family: GoogleSans;
    src: url(../fonts/google-sans/GoogleSans-Regular.ttf);
}

@font-face {
    font-family: GoogleSans-Bold;
    src: url(../fonts/google-sans/GoogleSans-Bold.ttf);
}

@font-face {
    font-family: SVN-SVN-A-Love-Of-Thunder;
    src: url(../fonts/SVN-SVN-A-Love-Of-Thunder.ttf);
}
@font-face {
    font-family: SVN-Bear;
    src: url(../fonts/SVN-Bear.ttf);
}